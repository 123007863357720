import styled from "styled-components";

const SlideContainer = styled.div`
  width: 33vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: 5px;

  @media (max-width: 768px) {
    width: 100vw;
    font-size: 10px;
    margin-top: 10px;
    justify-content:flex-start;
  }
`;

const HeaderInfo = styled.div`
  height: 27%;
  display: flex;
  line-height: 15px;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  @media (max-width: 768px) {
    height: fit-content;
    width: 100%;
    gap: 5px;
  }
`;

const HeaderInfoParagraph = styled.p`
  margin: 0;
  text-align: center;

  a {
    text-decoration: underline;
    color: black;
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
`;

const Link = styled.a`
  text-decoration: underline;
  color: black;
`;

const SliderImage = styled.img`
  height: 73%;
  display: flex;
  flex-grow: 1;

  @media (max-width: 768px) {
    width: auto;
  }
`;

const Slide = ({src, language}) => {
  return (
    <SlideContainer>
      {language === "spanish" && <HeaderInfo>
        <HeaderInfoParagraph>
          TIBERI BAR <br/>
          C/VILA I VILÀ, 67, BARCELONA <br/>
          643374005 - <a href='https://tiberibar.myrestoo.net/es/reservar' target="_blank" rel="noreferrer">RESERVAR</a> - HOLA@TIBERIBAR.COM
        </HeaderInfoParagraph>
        <HeaderInfoParagraph>
          ÑAM-ÑAM, GLU-GLU, BLA-BLA <br/>
          PLATOS PARA COMPARTIR Y VINOS NATURALES <br/>
        </HeaderInfoParagraph>
        <HeaderInfoParagraph>
          EN CASA MONTJUIC,<br/>
          DE TIBERI CLUB <br/>
        </HeaderInfoParagraph>
        <HeaderInfoParagraph>
          M. 19:00-24:00 - MI. 19:00-1:00 - J. 19:00-1:00<br/>
          V. 19:00-1:00 - S. 13:00-1:00 <br/>
          D. 13:00-16:00 & 19:00-24:00 <br/>
        </HeaderInfoParagraph>
        <Links>
          <Link href="https://www.instagram.com/tiberi.bar/" target="_blank">@TIBERI.BAR</Link>
          <Link href="https://www.instagram.com/tiberi.club/" target="_blank">@TIBERI.CLUB</Link>
        </Links>
      </HeaderInfo> }

      {language === "catalan" && <HeaderInfo>
        <HeaderInfoParagraph>
          TIBERI BAR <br/>
          C/VILA I VILÀ, 67, BARCELONA <br/>
          643374005 - <a href='https://tiberibar.myrestoo.net/es/reservar' target="_blank" rel="noreferrer">RESERVAR</a> - HOLA@TIBERIBAR.COM
        </HeaderInfoParagraph>
        <HeaderInfoParagraph>
          NYAM-NYAM, GLUP-GLUP, BLA-BLA <br/>
          PLATS PER COMPARTIR I VINS NATURALS <br/>
        </HeaderInfoParagraph>
        <HeaderInfoParagraph>
          A DINS DE CASA MONTJUIC,<br/>
          DE TIBERI CLUB <br/>
        </HeaderInfoParagraph>
        <HeaderInfoParagraph>
          DT. 19:00-24:00 - DC. 19:00-1:00 - DJ. 19:00-1:00<br/>
          DV. 19:00-1:00 - DS. 13:00-1:00<br/>
          DG. 13:00-16:00 & 19:00-24:00 <br/>
        </HeaderInfoParagraph>
        <Links>
          <Link href="https://www.instagram.com/tiberi.bar/" target="_blank">@TIBERI.BAR</Link>
          <Link href="https://www.instagram.com/tiberi.club/" target="_blank">@TIBERI.CLUB</Link>
        </Links>
      </HeaderInfo> }

      {language === "english" &&
      <HeaderInfo>
        <HeaderInfoParagraph>
          TIBERI BAR <br/>
          C/VILA I VILÀ, 67, BARCELONA <br/>
          643374005 - <a href='https://tiberibar.myrestoo.net/es/reservar' target="_blank" rel="noreferrer">RESERVATION</a> - HOLA@TIBERIBAR.COM
        </HeaderInfoParagraph>
        <HeaderInfoParagraph>
          YUM-YUM, GLUG-GLUG, BLAH-BLAH <br/>
          PLATES TO SHARE AND NATURAL WINES <br/>
        </HeaderInfoParagraph>
        <HeaderInfoParagraph>
          AT CASA MONTJUIC,<br/>
          BY TIBERI CLUB <br/>
        </HeaderInfoParagraph>
        <HeaderInfoParagraph>
          TUE. 19:00-24:00 - WED. 19:00-1:00 - TH. 19:00-1:00<br/>
          FRI. 19:00-1:00 - SAT. 13:00-1:00 <br/>
          SUN. 13:00-16:00 & 19:00-24:00 <br/>
        </HeaderInfoParagraph>
        <Links>
          <Link href="https://www.instagram.com/tiberi.bar/" target="_blank">@TIBERI.BAR</Link>
          <Link href="https://www.instagram.com/tiberi.club/" target="_blank">@TIBERI.CLUB</Link>
        </Links>
      </HeaderInfo> }
      <SliderImage src={src}/>
    </SlideContainer>
  )

}

export default Slide;
